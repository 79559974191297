/* eslint-disable */
import Foundation from './foundation';

$(window).on('resize', function () {
  if ($('.main-menu-container').hasClass('active')) {
    $('.menu-icon').click();
  }
});

$('.menu-icon').on('click', function () {
  $(document).on('click', closeBurgerMenu);
});

function closeBurgerMenu(e) {
  if (!e.target.closest('.header-menu') && !e.target.closest('.menu-icon')) {
    $('.menu-icon').click();
    $(document).off('click', closeBurgerMenu);
  }
}

function headerScrollUp_scrollDown() {
  let lastScrollTop = 0;
  const header = $('.header');
  let headerHeight = header.outerHeight() + parseFloat(header.css('top'));
  const firstSection = header.next().find('section:first-child');
  let heightOfFirstSection = firstSection.outerHeight();

  function handlerScroll() {
    let scrollTop = $(window).scrollTop();
    if (firstSection.length !== 0) {
      heightOfFirstSection = firstSection.outerHeight();
      if (
        scrollTop > lastScrollTop &&
        scrollTop >= heightOfFirstSection - headerHeight
      ) {
        header.addClass('hidden_header');
      }

      if (scrollTop < lastScrollTop || scrollTop < 0 || scrollTop === 0) {
        header.removeClass('hidden_header');
      }

      lastScrollTop = scrollTop;
    } else {
      if (scrollTop > lastScrollTop) {
        header.addClass('hidden_header');
      }

      if (scrollTop < lastScrollTop || scrollTop < 0 || scrollTop === 0) {
        header.removeClass('hidden_header');
      }

      lastScrollTop = scrollTop;
    }
  }

  $(window).on('resize', function () {
    heightOfFirstSection = firstSection.outerHeight();
    headerHeight = header.outerHeight() + parseFloat(header.css('top'));
  });

  $(window).on('load changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.is('medium down')) {
      $(window).scroll(handlerScroll);
    }
  });
}

headerScrollUp_scrollDown();
