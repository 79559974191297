// /* eslint-disable */
import Foundation from './inc/foundation';
import 'slick-carousel';
import './inc/header.js';
import {
  watchSection,
  openWhitepaperPopup,
  scrollToBlock,
  addedSelect2,
} from './inc/functions';
import 'select2';

$(document).foundation();
/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('is-active');

    const mobileMenu = $('.main-menu-container');
    mobileMenu.toggleClass('active');
    $('body').toggleClass('lock');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
  });

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});

$(document).on('keydown', function (e) {
  if (
    (e.metaKey || e.ctrlKey) &&
    String.fromCharCode(e.which).toLowerCase() === 'm'
  ) {
    $('.mesh').toggleClass('hide');
  }
});
$(window).on('load changed.zf.mediaquery', function () {
  const features_small_sections = $('.features_small');
  if (features_small_sections) {
    features_small_sections.each(function (_, item) {
      const firstParent = $(item).find('.js_start_parent');
      const secondParent = $(item).find('.js_second_parent');
      const sliderParent = $(item).find('.features_small__cards_body');

      if (Foundation.MediaQuery.is('large')) {
        setTimeout(function () {
          firstParent.appendTo(sliderParent);
        }, 200);
      } else {
        firstParent.appendTo(secondParent);
      }
    });
  }

  const cta_sections = $('.cta');
  if (cta_sections) {
    cta_sections.each(function (_, item) {
      const formCard = $(item).find('.change_position_mobile');
      const starPosition = $(item).find('.cta__form_block');
      const secondPosition = $(item).find('.cta__mobile_form');
      if (Foundation.MediaQuery.is('large')) {
        formCard.appendTo(starPosition);
      } else {
        formCard.appendTo(secondPosition);
      }
    });
  }
});

$(window).on('scroll', watchSection);

$(function () {
  addedSelect2();
});

$(document).on('gform_post_render', addedSelect2);

$(document).on('gform_confirmation_loaded', scrollToSuccessMessageForm);

function toggleVideoPlay() {
  const videoBlocks = document.querySelectorAll('.big_card__video');
  if (videoBlocks.length > 0) {
    videoBlocks.forEach(function (block) {
      const video = block.querySelector('video');
      const fullWidthButton = block.querySelector('.video_full_width_button');
      //=== play/pause the video =======
      video.addEventListener('click', function () {
        if (video.paused) {
          block.classList.add('active_video');
          this.play();
        } else {
          block.classList.remove('active_video');
          this.pause();
        }
      });

      //full width on/off
      fullWidthButton.addEventListener('click', function () {
        //=== on
        const videoParent = this.closest('.big_card__video');
        videoParent.classList.add('full_width');
        document.body.classList.add('lock');
        this.classList.add('hidden');

        //=== off
        document.addEventListener('click', offFullWidth);

        function offFullWidth(e) {
          if (
            e.target !== video &&
            !e.target.closest('.video_full_width_button')
          ) {
            fullWidthButton.classList.remove('hidden');
            videoParent.classList.remove('full_width');
            document.body.classList.remove('lock');
            document.removeEventListener('click', offFullWidth);
          }
        }
      });
    });
  }
}

toggleVideoPlay();

watchSection();

function openPopup() {
  $(document).on('click', '[data-zum-whitepaper]', function (e) {
    openWhitepaperPopup(e);
  });
}

openPopup();

function testimonialSlider() {
  const sections = $('.testimonial_slider');
  sections.each(function (_, item) {
    let slider = $(item).find('.slider__body');
    slider.slick({
      infinite: true,
      slidesToShow: 1,
      arrows: true,
      dots: true,
      prevArrow: $(item).closest('section').find('.slick-prev'),
      nextArrow: $(item).closest('section').find('.slick-next'),
      appendDots: $(item).closest('section').find('.slider__dots'),
    });
  });
}

testimonialSlider();

function showFormFor_CTA_section() {
  const sections = $('.cta');
  if (!sections) return;
  sections.each(function (_, item) {
    const image = $(item).find('.cta__image_block');
    const form = $(item).find('.cta__form_block');
    if (!image && !form) return;
    image.one('click', function () {
      $(this).addClass('move');
      form.addClass('show');
    });
  });
}

showFormFor_CTA_section();

//Smooth Scroll=======
$('a[href*="#"]').on('click', function (e) {
  const $this = $(this);
  if (
    $this.closest('[data-tabs]').length ||
    $this.closest('[data-accordion]').length
  )
    return;

  const href = $this.attr('href');
  if (href.indexOf('#') === 0) {
    e.preventDefault();
    scrollToBlock(href);
  } else {
    let split = href.split('#');
    let location = window.location.href.replace('#', '');
    if (location === split[0]) {
      e.preventDefault();
      scrollToBlock('#' + split[1]);
    }
  }
});
let locationHash = window.location.hash;
if (locationHash) {
  window.location.hash = '';
  scrollToBlock(locationHash);
}

function showCookie() {
  const openCookieButton = $('a[href="#show_cookie"]');
  if (openCookieButton.length === 0) return;
  openCookieButton.on('click', (e) => e.preventDefault());
  openCookieButton.addClass('borlabs-cookie-preference');
}

showCookie();

function scrollToSuccessMessageForm(_, form_id) {
  const successMessage = $(`.gform_confirmation_message_${form_id}`);
  if (successMessage.length === 0) return;
  let offset = $('.header').outerHeight();
  if (!offset) {
    offset = 0;
  }
  setTimeout(function () {
    successMessage.addClass('scroll_to_success_massage');

    $('html,body').animate(
      {
        scrollTop: successMessage.offset().top - (offset + 300),
      },
      400
    );
  }, 100);
}

function mouseScrollBlock(selector) {
  const container = document.querySelector(selector);
  if (!container) return;
  let isDragging = false;
  let startX, scrollLeft;

  container.addEventListener('mousedown', function (e) {
    isDragging = true;
    startX = e.pageX - container.offsetLeft;
    scrollLeft = container.scrollLeft;
  });

  container.addEventListener('mouseleave', function () {
    isDragging = false;
  });

  container.addEventListener('mouseup', function () {
    isDragging = false;
  });

  container.addEventListener('mousemove', function (e) {
    if (!isDragging) return;
    let x = e.pageX - container.offsetLeft;
    let walk = (x - startX) * 2;
    container.scrollLeft = scrollLeft - walk;
  });
}

mouseScrollBlock('.features_small .features_small__cards_body');

function hoverAnimation() {
  const sections = document.querySelectorAll('.cta_whitepaper');
  if (!sections) return;

  sections.forEach(function (item) {
    doAnimation(item);
  });

  function doAnimation(section) {
    const hoverCardsParent = section.querySelector(
        '.cta_whitepaper__hover-cards'
      ),
      bacCard_1 = section.querySelector('.hover_card.child_1'),
      bacCard_2 = section.querySelector('.hover_card.child_2'),
      bacCard_3 = section.querySelector('.hover_card.child_3');

    const animationOptions = {
      duration: 1000,
      fill: 'forwards',
      easing: 'cubic-bezier(0.68, -0.1, 0.265, 1.55)',
    };

    const hoverCardsParent__cords = {
        startPosition: [{ bottom: '-177px' }],
        endPosition: [{ bottom: '-99px' }],
      },
      bacCard_1__cords = {
        startPosition: [
          {
            bottom: '-75px',
            right: '-38px',
          },
        ],
        endPosition: [
          {
            bottom: '-35px',
            right: '-69px',
          },
        ],
      },
      bacCard_2__cords = {
        startPosition: [{ bottom: '-83px' }],
        endPosition: [{ bottom: '-53px' }],
      },
      bacCard_3__cords = {
        startPosition: [
          {
            bottom: '-100px',
            right: '-79px',
          },
        ],
        endPosition: [
          {
            bottom: '-60px',
            right: '-100px',
          },
        ],
      };

    section.addEventListener('mouseenter', function () {
      hoverCardsParent.animate(
        hoverCardsParent__cords.endPosition,
        animationOptions
      );
      bacCard_1.animate(bacCard_1__cords.endPosition, animationOptions);
      bacCard_2.animate(bacCard_2__cords.endPosition, animationOptions);
      bacCard_3.animate(bacCard_3__cords.endPosition, animationOptions);
    });

    section.addEventListener('mouseleave', function () {
      hoverCardsParent.animate(
        hoverCardsParent__cords.startPosition,
        animationOptions
      );
      bacCard_1.animate(bacCard_1__cords.startPosition, animationOptions);
      bacCard_2.animate(bacCard_2__cords.startPosition, animationOptions);
      bacCard_3.animate(bacCard_3__cords.startPosition, animationOptions);
    });
  }
}

hoverAnimation();

function badgesSlider() {
  const sections = $('.badges_block');
  if (sections.length === 0) return;
  sections.each(function (_, section) {
    let slider = $(section).find('.pc_slider');
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = slider.hasClass('slick-initialized');
      if (Foundation.MediaQuery.is('medium down')) {
        if (isSlider) {
          slider.slick('unslick');
        }
      } else if (!isSlider) {
        slider.slick({
          arrows: false,
          dots: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
        });
      }
    });
  });
}

badgesSlider();

function featuresSmallSlider() {
  const sections = $('.features_small');
  sections.each(function (_, item) {
    const slider = $(item).find('.features_small__cards-slider');
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = slider.hasClass('slick-initialized');

      if (Foundation.MediaQuery.is('large')) {
        if (isSlider) {
          slider.slick('unslick');
        }
      } else if (!isSlider) {
        setTimeout(function () {
          slider.slick({
            arrows: true,
            dots: true,
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
            appendDots: $(item).closest('section').find('.slider__dots'),
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            rows: 0,
          });
        }, 100);
      }
    });
  });
}

featuresSmallSlider();

function featuresSlider() {
  const sections = $('.features');
  sections.each(function (_, item) {
    const slider = $(item).find('.features__cards-slider');
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = slider.hasClass('slick-initialized');

      if (Foundation.MediaQuery.is('large')) {
        if (isSlider) {
          slider.slick('unslick');
        }
      } else if (!isSlider) {
        setTimeout(function () {
          slider.slick({
            arrows: true,
            dots: true,
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
            appendDots: $(item).closest('section').find('.slider__dots'),
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            rows: 0,
          });
        }, 100);
      }
    });
  });
}

featuresSlider();

function сardsSlider() {
  const sections = $('.text_and_cards');
  sections.each(function (_, item) {
    const slider = $(item).find('.slider');
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = slider.hasClass('slick-initialized');

      if (Foundation.MediaQuery.is('medium')) {
        if (isSlider) {
          slider.slick('unslick');
        }
      } else if (!isSlider) {
        setTimeout(function () {
          slider.slick({
            arrows: true,
            dots: true,
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
            appendDots: $(item).closest('section').find('.slider__dots'),
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            rows: 0,
          });
        }, 100);
      }
    });
  });
}

сardsSlider();
