$.fn.isInViewport = function () {
  if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop() - 200;
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return 0;
};

$.fn.isInViewportNoOffset = function () {
  if ($(this).length) {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop() - 200;
    var viewportBottom = viewportTop + $(window).height() - 100;

    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return 0;
};
export function watchSection() {
  const sections = $('[data-watch]');
  if (!sections) return;
  sections.each(function (_, item) {
    if ($(item).isInViewport()) {
      if ($(item).hasClass('see_section')) return;

      $(item).addClass('see_section');
    } else {
      $(item).removeClass('see_section');
    }
  });
}
export function openWhitepaperPopup(e) {
  const section = $(e.target).closest('section');
  const popup = section.find('.whitepaper .popup');
  popup.addClass('open');
  $('body').addClass('lock');

  function clickHandler(e) {
    if (
      e.target.closest('.popup__close') ||
      !e.target.closest('.popup__content')
    ) {
      popup.removeClass('open');
      $('body').removeClass('lock');
      $(document).off('click', clickHandler); //remove event click
    }
  }
  $(document).on('click', clickHandler);
}
export function scrollToBlock(id) {
  const block = $(id);

  if (!block.length) return;

  let offset = $('.header').outerHeight();
  if (!offset) {
    offset = 0;
  }

  setTimeout(function () {
    $('html,body').animate(
      {
        scrollTop: $(id).offset().top - (offset + 50),
      },
      400
    );
  }, 500);
}
export function addedSelect2() {
  const selects = $('select');
  if (selects.length === 0) return;
  selects.each(function (_, item) {
    $(item).select2({
      minimumResultsForSearch: Infinity, //remove search field
    });
    //remove duplicate select placeholder on options
    let firstOption = $(item).find('option:first');
    firstOption.prop('disabled', true);
  });
}
